<template>
  <div v-if="reload && review">

    <banner v-if="!submitForm" :word="word" @change="changeLanguage" @load="getBanner" />
    <router-view :mode="submitForm" @msg="getMsg" v-if="review && showBanner" />
    <foot v-if="!submitForm" :word="word" :reload="review" />
    <div v-if="message" class="alert">
      <div :class="isSuccess ? 'success alert-content' : ' alert-content'">
        {{ message }}
      </div>
    </div>

    <div class="cookies" v-if="onShow">
      <div>
        This website uses cookies to ensure you get the best experience on our
        website.
      </div>
      <div class="button">
        <div class="button-left" @click="onClose">Decline</div>
        <div class="button-right" @click="onClose">Allow All</div>
        <div class="close" @click="onClose">×</div>
      </div>
    </div>

    <div class="chooseLogin" :style="{top:store.state.scroll + 'px'}" v-if="store.state.show">
      <div class="box">
        <img class="closeImg" src="./assets/close.png" alt="" @click="closeLogin">
        <div class="title"> {{ word.menu.chooseProduct[language] }}</div>
        <div class="projectList">
          <div class="projectList_top" @click="toLogin">
            <img src="./assets/iconSMS.png" alt="">
            SMS
          </div>
          <div class="projectList_top" @click="toLoginWhatsApp">
            <img src="./assets/Whatsapp1.svg" alt="">
            WhatsApp
          </div>
        </div>

      </div>

    </div>

  </div>
</template>
<!-- eslint-disable -->
<script>
import banner from "./components/banner.vue";
import foot from "./components/foot.vue";
import axios from "@/utils/request/request.js";
import word from "./assets/json/language.json";
import store from "./store";

export default {
  name: "App",
  components: {
    banner,
    foot,
  },
  data() {
    return {
      chooseProduct: '',
      onShow: true,
      submitForm: false,
      message: "",
      isSuccess: false,
      word: word,
      reload: false,
      showBanner: false,
      review: true,
      language: null,
      store: store,
      lang: localStorage.getItem("language")
    };
  },


  watch: {
    $route: function (newVal, oldVal) {
      if (newVal.path != oldVal.path) {
        this.reload = false;
        setTimeout(() => {
          this.reload = true;
        });
      }
    },
    lang() {
      console.log("1111111111");
    },
  },

  mounted() {
    window.addEventListener("scroll", (res) => {
      const scrollTop = document.documentElement.scrollTop; // 查看滑动的距离
      store.commit("SETOFFSET", scrollTop)
    })
  },
  created() {
    this.word = word;
    this.reload = false;
    let hasLanguage = localStorage.getItem("language");
    if (!hasLanguage) {
      axios.get(`/ip`).then((res) => {
        this.language = res.data == "CN" ? "CN" : "EN";
        this.getPath();
        localStorage.setItem("language", this.language);
        document.title =
          res.data == "CN"
            ? "优尼科技-全球短信/语音，更快速，更专业, 直连运营商，覆盖全球国家"
            : "YoniTech-Global SMS/Voice, faster and more professional, directly connected to operators, covering countries around the world";
        this.reload = true;
      });
      this.defaultHeight =
        (700 * window.innerWidth) / 1920 - window.innerHeight / 2;
      setTimeout(() => {
        this.showBanner = true;
        this.reload = true;
        if (!this.language) {
          this.getPath();
          this.language = "EN";
          localStorage.setItem("language", this.language);
        }
      }, 800);
    } else {
      this.getPath();
      this.showBanner = true;
      this.reload = true;
    }
    this.language = localStorage.getItem("language");
  },
  methods: {
    handleClick() {
      console.log(this.lang);
    },
    toLoginWhatsApp() {
      window.open(`https://whatsapp.yoni-tech.com/console/#/entry/login`);
    },

    toLogin() {
      let lang = this.language == 'EN' ? 'en' : 'zh'
      window.open(`https://sgzz.yoni-tech.com/login?lang=${lang}`);
    },

    closeLogin() {
      document.querySelector('body').style.overflow = 'auto'
      store.commit('SHOW_DIGLOG', false)
    },


    onClose() {
      this.onShow = false;
    },
    getPath() {
      if (this.$route.href == "/?submitForm") {
        this.submitForm = true;
      }
    },
    getMsg(e, boolean) {
      this.message = e;
      this.isSuccess = boolean;
      setTimeout(() => {
        this.message = "";
      }, 1500);
    },
    getBanner() {
      this.showBanner = true;
    },
    changeLanguage() {
      this.language = window.localStorage.getItem("language")
      this.review = false;
      setTimeout(() => {
        this.review = true;
      });
    },
  },
};
</script>

<style lang="scss">
.chooseLogin {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 300px;
    background: url("./assets/result.svg") no-repeat;
    background-size: 100% 100%;

    .closeImg {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      cursor: pointer;
    }

    .title {
      font-size: 18px;
      margin: 60px 0 0 90px;
    }

    .projectList {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
        height: 50px;
      }

      &_top {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        margin: 40px 80px;
        justify-content: center;
        align-items: center;

        &:hover {
          transform: scale(1.2);
          transition: all 0.3s ease-in-out;
        }
      }

      &_bottom {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        margin: 40px 80px;
        justify-content: center;
        align-items: center;

        &:hover {
          transform: scale(1.2);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

.homeMiddle_right {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.homeMiddle_list {
  margin-bottom: 0px !important;
}
* {
  font-family: "openSans";
}

.font_detail {
  bottom: 35% !important;
}

.homeMiddle {
  padding: 0 6%;
}
.container {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;

  .chevron {
    position: absolute;
    width: 38px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
    margin-bottom: 10px;
  }
  .chevron:first-child {
    animation: move 3s ease-out 1s infinite;
  }
  .chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
  }
  .chevron:before,
  .chevron:after {
    content: " ";
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
  }
  .chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
  }
  .chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }
  @keyframes move {
    25% {
      opacity: 1;
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }
  .text {
    display: block;
    margin-top: 75px;
    margin-left: -30px;
    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0.25;
    animation: pulse 2s linear alternate infinite;
  }
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}
.cookies {
  border-radius: 10px;
  box-shadow: 0px 0px 24px -5px #000;
  padding: 18px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80% !important;
  position: fixed;
  bottom: 10px;
  margin: 0 auto;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  font-size: 14px;
  z-index: 10;
  .button {
    display: flex;
    align-items: center;
    .button-left {
      cursor: pointer;
      background-color: #1a1e22ff;
      padding: 8px 24px;
      color: #fff;
      font-size: 14px;
    }
    .button-right {
      cursor: pointer;
      background-color: #1a1e22ff;
      padding: 8px 24px;
      color: #fff;
      font-size: 14px;
      margin-left: 10px;
    }
    .close {
      cursor: pointer;
      transform: scale(1.5);
      margin-left: 20px;
    }
  }
}
.look-price {
  width: 300px;
  margin: 50px auto 100px;
  height: 60px;
  font-size: 20px;
  border-radius: 30px;
  cursor: pointer;
  line-height: 60px;
  text-align: center;
  background-color: #108ccf;
  color: #fff;
}
.alert {
  position: fixed;
  top: 12%;
  left: 0;
  width: 100%;
  z-index: 10070;
  .alert-content {
    border-radius: 16px;
    text-align: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    line-height: 50px;
    height: 50px;
    width: 500px;
    margin: 0 auto;
    font-size: 20px;
    color: #e6a23c;
    background-color: #fdf6ec;
    &.success {
      background-color: #f0f9eb;
      color: #67c23a;
    }
  }
}
body {
  width: 100%;
  padding: 0;
  margin: 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
a {
  text-decoration: none;
  color: #393939;
}
@font-face {
  font-family: "iconfont"; /* Project id 2791796 */
  src: url("//at.alicdn.com/t/font_2791796_315ij951rya.woff2?t=1635217737958")
      format("woff2"),
    url("//at.alicdn.com/t/font_2791796_315ij951rya.woff?t=1635217737958")
      format("woff"),
    url("//at.alicdn.com/t/font_2791796_315ij951rya.ttf?t=1635217737958")
      format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
</style>
