import { createStore } from 'vuex'

export default createStore({
  state: {
    show: false,
    scroll: 0
  },
  mutations: {
    SHOW_DIGLOG(state, value) {
      state.show = value
    },
    SETOFFSET(state, value) {
      state.scroll = value
    },
  },
  actions: {
  },
  modules: {
  }
})
