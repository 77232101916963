<template>
  <div v-if="word" class="foot">
    <div class="foot-info">
      <div class="foot-left" :class="{ left: language == 'EN' }">
        <div style="position: relative">
          <div class="foot-title">{{ word.foot.product.title[language] }}</div>
          <div @click="toSMS">{{ word.menu.sms[language] }}</div>
          <div @click="toVoice">{{ word.menu.voice[language] }}</div>
          <div @click="toPlatform">{{ word.menu.phone[language] }}</div>
          <div @click="toWhatsApp">WhatsApp</div>
          <div class="select" @mouseleave="show = false" v-if="show">
            <!-- <div class="select_box"></div> -->
            <div class="triangle-up"></div>
            <div class="select_item">
              <img src="./../assets/iconWAP.png" alt="" />
              WhatsApp API
            </div>
            <div class="select_item" @click="toCampaign">
              <img src="./../assets/email-campaign.png" alt="" />
              Campaign
            </div>
            <div class="select_item" @click="toBOT">
              <img src="./../assets/bot.png" alt="" />
              BOT
            </div>
            <div class="select_item" @click="toContact">
              <img src="./../assets/contact.png" alt="" />
              Contact
            </div>
            <div class="select_item" @click="toGrowth">
              <img src="./../assets/growth.png" alt="" />
              Growth Tool
            </div>
            <!-- <div class="select_item" @click="toCTWA">
              <img src="./../assets/ctwa.png" alt="" />
              CTWA
            </div> -->
          </div>
        </div>

        <div>
          <div class="foot-title">
            {{ word.foot.developer.title[language] }}
          </div>
          <div @click="toDoc">
            {{ word.foot.developer.developer1[language] }}
          </div>
        </div>
        <div
          class="about"
          :class="{ maxWeight: language == 'CN' }"
          style="max-width: 150px"
        >
          <div class="foot-title">{{ word.foot.company.title[language] }}</div>

          <div @click="toAboutAs" style="cursor: pointer">
            {{ word.foot.company.title3[language] }}
          </div>
          <div @click="toService" style="cursor: pointer">
            {{ word.foot.developer.developer3[language] }}
          </div>
          <div @click="toPrivacyPolicy" style="cursor: pointer">
            {{ word.foot.developer.developer4[language] }}
          </div>
          <!-- <div>
            {{ word.foot.company.address[language] }}:
            {{ word.foot.company.specific[language] }}
          </div> -->
        </div>
        <div class="about" style="max-width: 165px">
          <div class="foot-title">{{ word.foot.company.title2[language] }}</div>
          <div>
            <a href="mailto:service@yoni-tech.com">service@yoni-tech.com</a>
          </div>
          <!-- <div @click="toService" style="cursor: pointer">
            {{ word.foot.developer.developer3[language] }}
          </div>
          <div @click="toPrivacyPolicy" style="cursor: pointer">
            {{ word.foot.developer.developer4[language] }}
          </div> -->
        </div>
      </div>
      <div class="foot-right">
        <div class="qr">
          <img src="./../assets/LinkedIn.png" alt="" />
          <div>
            {{ language == "CN" ? "公司领英" : "LinkedIn Company Page" }}
          </div>
        </div>
        <div class="qr">
          <img src="./../assets/facebook.png" alt="" />
          <div>
            {{ language == "CN" ? "公司FACEBOOK" : "Facebook Company Page" }}
          </div>
        </div>
        <div class="qr">
          <img src="./../assets/wechat.jpg" alt="" />
          <div>
            {{ language == "CN" ? "微信公众号" : "Wechat Office Account" }}
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <!-- <div v-if="language == 'CN'">增值电信业务经营许可证号：B2-20213538</div> -->
      <div>Copyright © 2016-2024 YONI-TECH</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot",
  props: {
    reload: Boolean,
    word: Object,
  },
  data() {
    return {
      show: false,
      language: null,
    };
  },
  created() {
    this.language = localStorage.getItem("language");
  },
  watch: {
    reload: function () {
      this.language = localStorage.getItem("language");
    },
  },
  methods: {
    toDoc() {
      window.open("https://docs-yoni-tech.readme.io/reference/summary");
    },
    toWhatsApp(e) {
      window.open(
        "https://docs-yoni-tech.readme.io/reference/whatsapp_getting-started"
      );
    },
    toCampaign(e) {
      this.$router.push({ path: "/campaign" });
    },
    toBOT(e) {
      this.$router.push({ path: "/bot" });
    },
    toContact(e) {
      this.$router.push({ path: "/contact" });
    },
    toAboutAs() {
      this.$router.push({ path: "/about" });
    },
    toGrowth(e) {
      this.$router.push({ path: "/growthTool" });
    },
    toCTWA(e) {
      this.$router.push({ path: "/CTWA" });
    },
    toSMS(e) {
      this.$router.push({ path: "/sms" });
    },
    toVoice() {
      this.$router.push({ path: "/voice" });
    },
    toPlatform() {
      this.$router.push({ path: "/phoneCheck" });
    },
    toService() {
      this.$router.push({ path: "/termsService" });
    },
    toPrivacyPolicy() {
      this.$router.push({ path: "/privacyPolicy" });
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  background: #fff;
  border-radius: 10px;
  width: 150px;
  position: absolute;
  top: -10px;
  left: 100px;

  .select_box {
    position: absolute;
    width: 126px;
    height: 40px;
    background: red;
    z-index: 99999;
    left: -104px;
    top: 135px;
  }

  .triangle-up {
    z-index: 202;
    position: absolute;
    top: 176px;
    left: -10px;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent !important;
    border-right: 8px solid #ffffff !important;
    border-bottom: 10px solid transparent !important;
    border-top: 10px solid transparent !important;
  }

  .triangle-up-shadow {
    z-index: 199;
    position: absolute;
    top: calc(110% - 11px);
    left: 50%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.02);
  }

  &_item {
    padding: 15px 10px 15px 10px;
    color: black;
    font-size: 12px;
    cursor: pointer;
    color: black;
    font-weight: 600;
    img {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
  }
}

.maxWeight{
  max-width: 168px !important;
}
.foot {
  height: 450px;
  background-color: #102136;
  color: #eee;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: end;
  .foot-info {
    box-sizing: border-box;
    padding-left: 5%;
    // display: flex;
    // align-items: center;
    // align-content: flex-end;
    margin-bottom: 50px;
    width: 100%;
    position: relative;

    .left {
      > div {
        > div {
          margin: 22px 0 !important;
        }
      }
    }

    .foot-left {
      display: flex;
      height: 200px;
      > div {
        margin: 20px;
        max-width: 120px;
        width: 20%;
        > div {
          margin: 20px 0;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .about {
        max-width: 120px;
        width: 50%;
        > div {
          cursor: text;
        }
        a {
          color: #eee !important;
          white-space: nowrap;
        }
      }
      .foot-title {
        cursor: text;
        font-size: 18px;
        color: #cccccc;
        font-weight: 600;
        margin-bottom: 28px;
      }
    }
    .foot-right {
      padding-top: 30px;
      display: flex;
      position: absolute;
      right: 4%;
      top: 0px;
      .qr {
        width: 120px;
        text-align: center;
        margin-left: 40px;
        img {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .copyright {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    border-top: 1px solid #999;
    text-align: center;
    font-size: 13px;
    div {
      margin-top: 5px;
    }
  }
}

.language {
  position: relative;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 12px;
    margin-left: 2px;
    line-height: 1;
    transition: all 0.4s ease;
  }
  &:hover {
    i {
      transform: rotate(180deg);
    }
    .language-select {
      display: block;
    }
  }

  .languageBg {
    position: absolute;
    left: -30px !important;
    width: 300px;
    height: 300px;
  }

  .select1 {
    &:hover {
      .language-select {
        display: block !important;
      }
    }
  }

  .language-select1 {
    width: 85% !important;
  }

  .language-select {
    display: none;
    padding-top: 5%;
    position: absolute;
    top: 70%;
    left: 0;
    width: 100%;
    padding: 20px;
    &:hover {
      display: block;
    }
    .triangle-up {
      z-index: 202;
      position: absolute;
      top: 30px;
      left: 50%;
      margin-left: -5px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid #ffffff;
    }

    .triangle-up1 {
      left: -7% !important;
      top: 218% !important;
      border-left: 8px solid transparent !important;
      border-right: 8px solid #ffffff !important;
      border-bottom: 10px solid transparent !important;
      border-top: 10px solid transparent !important;
    }

    .jiantou {
      display: flex;
      justify-content: space-between;
      padding-left: 25px !important;
      position: relative;
      img {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -56%);
        width: 25px;
        height: 25px;
        vertical-align: middle;
      }
    }

    .languageWidth {
      width: 180px !important;
    }

    .triangle-up2 {
      left: -7% !important;
      top: 170% !important;
      border-left: 8px solid transparent !important;
      border-right: 8px solid #ffffff !important;
      border-bottom: 10px solid transparent !important;
      border-top: 10px solid transparent !important;
    }

    .languageLeft {
      left: 190px !important;
    }

    .triangle-up3 {
      left: -6% !important;
      top: 170% !important;
      border-left: 8px solid transparent !important;
      border-right: 8px solid #ffffff !important;
      border-bottom: 10px solid transparent !important;
      border-top: 10px solid transparent !important;
    }
    .triangle-up-shadow {
      z-index: 199;
      position: absolute;
      top: calc(110% - 11px);
      left: 50%;
      margin-left: -7px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 12px solid rgba(0, 0, 0, 0.02);
    }
    .language-select-item {
      z-index: 200;
      position: absolute;
      top: 110%;
      left: 0;
      width: 100%;
      background-color: #fff;
      text-align: center;
      border-radius: 8px;
      padding: 10px 0;
      box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
      &.product-item {
        padding: 0;

        > div {
          cursor: text;
          flex-grow: 1;
          border-left: 1px solid #e2e2e2;
          padding: 8px 0;
          cursor: pointer;

          &:first-of-type {
            border-left: 0;
          }
          .product-title {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            margin-bottom: 5px;
            text-align: left !important;
          }
          .product-row {
            text-align: left !important;
            box-sizing: border-box;
            padding-left: 15px;
            img {
              width: 20px;
              height: 20px;
              vertical-align: middle;
              margin-right: 3px;
            }
            cursor: pointer;
            height: 50px;
            line-height: 50px;
            margin: 0 12px;

            a {
              color: black !important;
            }
            &.active {
              background-color: #e2e2e2;
              a {
                color: rgba(4, 18, 61, 0.7);
              }
            }
            &:hover {
              background-color: #e2eff7;
            }
          }
        }
      }
      &.language-select {
        > div {
          height: 50px;
          line-height: 50px;
          &:hover {
            background-color: #e2eff7;
          }
        }
        .active {
          background-color: #e2e2e2;
        }
      }
    }
  }
}
</style>
