<template>
  <div v-if="word && language">
    <div class="banner animate__animated animate__fadeInLeft" :class="{ scroll: scrollTop > 10 }">
      <div class="banner-menu">
        <router-link to="/"><img class="logo" src="./../assets/logo.png" alt="" /></router-link>
        <div class="menu">
          <div v-for="item in menu" :key="item" class="menu-item" :class="{
              active:
                item != 'home'
                  ? url == '/' + item ||
                    ((url == '/sms' ||
                      url == '/voice' ||
                      url == '/phoneCheck') &&
                      item == 'product')
                  : url == '/',
              'docment language product': item == 'product',
            }">
            <template v-if="item != 'product'">
              <template v-if="item == 'docment'">
                <div class="language menu-item1">
                  {{ word.menu.docment[language]
                  }}<i class="iconfont">&#xe642;</i>
                  <div class="language-select animate__animated animate__fadeInUp">
                    <div class="triangle-up"></div>
                    <div class="triangle-up-shadow"></div>
                    <div style="width: 150px;" class="language-select-item language-select">
                      <div class="smsBanner" style="color: black" @click="goToSMSApi()">
                        <img src="./../assets/iconSMS.png" alt="" />
                        SMS&nbsp;&nbsp;
                      </div>
                      <div class="smsBanner" style="color: black" @click="goToVoice()">
                        <img src="./../assets/voicesIcon.png" alt="" />
                        Voice&nbsp;&nbsp;
                      </div>

                      <div class="smsBanner2 " style="color: black" @click="goToSMSWhats()">
                        <img src="./../assets/Whatsapp1.svg" alt="" />
                        WhatsApp
                      </div>
                      <div class="smsBanner" style="color: black" @click="goToCheck()">
                        <img src="./../assets/jiancheIcon.png" alt="" />
                        Number Check&nbsp;&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <router-link v-else :to="item != 'home' ? '/' + item : '/'">{{
                word && word.menu ? word.menu[item][language] : ""
              }}</router-link>
            </template>
            <template v-if="item == 'product'">
              <div @mouseleave="mouseleave">
                {{ word && word.menu ? word.menu[item][language] : ""
                }}<i class="iconfont">&#xe642;</i>
                <div class="language-select language-select1 animate__animated animate__fadeInUp">
                  <div class="triangle-up"></div>
                  <div class="triangle-up-shadow"></div>
                  <div class="language-select-item product-item" :class="{ languageWidth: language == 'EN' }">
                    <div id="jiantou1" class="jiantou" @mouseover="titleIndex = 1" @click="titleIndex = 1">
                      <div class="product-title">
                        {{ word && word.menu ? word.menu.sms[language] : "" }}
                        <img src="./../assets/jiantou.png" alt="" />
                      </div>
                    </div>
                    <div id="jiantou2" @mouseover="titleIndex = 2" class="jiantou" @click="titleIndex = 2">
                      <div class="product-title">
                        {{ word && word.menu ? word.menu.voice[language] : "" }}
                        <img src="./../assets/jiantou.png" alt="" />
                      </div>
                    </div>
                    <div id="jiantou3" @mouseover="titleIndex = 3" class="jiantou" @click="titleIndex = 3">
                      <div class="product-title">
                        {{ word && word.menu ? word.menu.phone[language] : "" }}
                        <img src="./../assets/jiantou.png" alt="" />
                      </div>
                    </div>

                    <div id="jiantou4" @mouseover="titleIndex = 4" class="jiantou" @click="titleIndex = 4">
                      <div class="product-title">
                        {{
                          word && word.menu ? word.menu.whatsApp[language] : ""
                        }}
                        <img src="./../assets/jiantou.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="titleIndex == 1" class="language-select select1 animate__animated animate__fadeInUp" :style="{ left: language == 'EN' ? '200px' : '160px' }">
                  <div class="languageBg"></div>
                  <div class="triangle-up triangle-up1"></div>
                  <div class="triangle-up-shadow"></div>
                  <div class="language-select-item product-item">
                    <div>
                      <div :class="{ active: url == '/sms' }" class="product-row">
                        <img src="./../assets/iconYZM.png" alt="" />
                        <router-link @click="titleIndex = 0" :to="'sms'">{{
                          word && word.product ? word.product.otp[language] : ""
                        }}</router-link>
                      </div>
                      <div :class="{ active: url == '/market' }" class="product-row">
                        <img src="./../assets/iconYxYzm.png" alt="" />
                        <router-link @click="titleIndex = 0" :to="'market'">{{
                          word && word.product
                            ? word.product.marketing[language]
                            : ""
                        }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="titleIndex == 2" class="language-select top1 select1 animate__animated animate__fadeInUp" :style="{ left: language == 'EN' ? '200px' : '160px' }">
                  <div class="languageBg"></div>
                  <div class="triangle-up triangle-up4"></div>
                  <div class="triangle-up-shadow"></div>
                  <div class="language-select-item product-item">
                    <div>
                      <div :class="{ active: url == '/voice' }" class="product-row">
                        <img src="./../assets/iconYX.png" alt="" />
                        <router-link @click="titleIndex = 0" :to="'voice'">{{
                          word && word.product
                            ? word.product.voice[language]
                            : ""
                        }}</router-link>
                      </div>
                      <div :class="{ active: url == '/trunk' }" class="product-row">
                        <img src="./../assets/iconSIP.png" alt="" />
                        <router-link @click="titleIndex = 0" :to="'trunk'">SIP Trunk</router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="titleIndex == 3" class="language-select top2 select1 animate__animated animate__fadeInUp" :style="{ left: language == 'EN' ? '200px' : '160px' }">
                  <div class="languageBg"></div>
                  <div class="triangle-up triangle-up2"></div>
                  <div class="triangle-up-shadow"></div>
                  <div class="language-select-item product-item">
                    <div>
                      <div :class="{ active: url == '/phoneCheck' }" class="product-row">
                        <img src="./../assets/iconJC.png" alt="" />
                        <router-link @click="titleIndex = 0" :to="'phoneCheck'">{{
                            word && word.product
                              ? word.product.number[language]
                              : ""
                          }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="titleIndex == 4" class="language-select select1 width animate__animated animate__fadeInUp" :style="{ left: language == 'EN' ? '200px' : '160px' }">
                  <div class="languageBg"></div>
                  <div class="triangle-up triangle-up3"></div>
                  <div class="triangle-up-shadow"></div>
                  <div class="language-select-item product-item">
                    <div>
                      <div :class="{ active: url == '/whatsApp' }" class="product-row product_WA">
                        <img src="./../assets/iconWAP.png" alt="" />

                        <a @click="goToWhatsApp"> WhatsApp API</a>
                      </div>
                      <div :class="{ active: url == '/campaign' }" class="product-row product_WA">
                        <img src="./../assets/email-campaign.png" alt="" />

                        <router-link @click="titleIndex = 0" :to="'campaign'"> Campaign</router-link>
                      </div>
                      <div :class="{ active: url == '/bot' }" class="product-row product_WA">
                        <img src="./../assets/bot.png" alt="" />

                        <router-link @click="titleIndex = 0" :to="'bot'"> BOT</router-link>
                      </div>
                      <div :class="{ active: url == '/contact' }" class="product-row product_WA">
                        <img src="./../assets/contact.png" alt="" />
                        <router-link @click="titleIndex = 0" :to="'contact'"> Contact</router-link>
                      </div>

                      <div :class="{ active: url == '/inbox' }" class="product-row product_WA">
                        <img src="./../assets/inbox.png" alt="" />
                        <router-link @click="titleIndex = 0" :to="'inbox'"> Inbox</router-link>
                      </div>

                      <div :class="{ active: url == '/growthTool' }" class="product-row product_WA">
                        <img src="./../assets/growth.png" alt="" />

                        <router-link @click="titleIndex = 0" :to="'growthTool'"> Growth Tool</router-link>
                      </div>

                      <!-- <div
                        :class="{ active: url == '/CTWA' }"
                        class="product-row product_WA"
                      >
                        <img src="./../assets/ctwa.png" alt="" />

                        <router-link @click="titleIndex = 0" :to="'CTWA'"
                          >CTWA</router-link
                        >
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="menu-item login-btn" @click="chooseLogin">
            {{ word && word.menu ? word.menu.login[language] : "" }}
          </div>
          <div class="menu-item login-btn sign-up" @click="toSignUp">
            {{ word && word.menu ? word.menu.signUp[language] : "" }}
          </div>
          <div class="menu-item language">
            {{ language == "CN" ? "简体中文" : "English"
            }}<i class="iconfont">&#xe642;</i>
            <div class="language-select animate__animated animate__fadeInUp">
              <div class="triangle-up"></div>
              <div class="triangle-up-shadow"></div>
              <div class="language-select-item language-select">
                <div style="color: black" @click="chooseLanguage('CN')" :class="language == 'CN' ? 'active' : ''">
                  简体中文
                </div>
                <div style="color: black" @click="chooseLanguage('EN')" :class="language == 'EN' ? 'active' : ''">
                  English
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="!showBannerText"
      class="banner-img animate__animated animate__fadeInLeft"
    >
      <img
        @load="imgLoad"
        :src="require('./../assets/banner1' + language + '.png')"
        alt=""
      />
    </div> -->

    <!-- <div class="stk">
      <div class="stkBg"></div>
      <img src="./../assets/home-hero.jpg" alt="" v-if="pictureBg == 'Home'" />
      <img src="./../assets/verfiyBg.jpg" alt="" />
      <div class="font_detail">
        <h2>{{ word.menu.titleTop[language] }}</h2>
        <p class="jianjie">
          {{ word.menu.jieshao[language] }}
        </p>

        <div class="jiage" @click="goToPrice">
          {{ word.menu.jiage[language] }}
        </div>
      </div>
    </div> -->
    <!-- <div
      v-if="showBannerText"
      class="banner-img animate__animated animate__fadeInLeft"
    >
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in imgList" :key="item">
            <div>
              <img
                :src="require('./../assets/banner' + item + language + '.png')"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div> -->
  </div>

</template>

<!-- eslint-disable -->
<script>
import "animate.css";
import word from "../assets/json/language.json";
import store from "../store";
export default {
  name: "banner",
  props: {
    word: Object,
  },

  data() {
    return {
      titleIndex: 0,
      picture: ["home-hero", "verfiyBg"],
      menu: ["home", "product", "price", "docment", "about"],
      showBannerText: false,
      scrollTop: 0,
      word: word,
      language: "",
      imgList: [1, 2, 3],
      url: "",
      pictureBg: "home-hero",
    };
  },
  created() {
    this.url = this.$route.href;
    this.language = localStorage.getItem("language");
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollTop = document.documentElement.scrollTop;
    });
    setTimeout(() => {
      var mySwiper = new Swiper(".swiper-container", {
        loop: true, // 循环模式选项
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
        },
      });
    }, 1000);

  },
  computed: {
    getLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    getLanguage: function (val) {
      this.language = val;
    },
  },
  destroyed() {
    document.querySelector('body').style.backgroundColor = '#fff';
  },
  methods: {
  },
  watch: {
    $route: function (val) {
      this.url = val.href;
      this.pictureBg = val.name;
    },
  },
  methods: {
    chooseLogin() {
      store.commit('SHOW_DIGLOG', true)
      document.querySelector('body').style.overflow = 'hidden'
    },
    goToVoice() {
      window.open(
        "https://docs-yoni-tech.readme.io/reference/voice_sip-trunk"
      );
    },
    goToCheck() {
      window.open(
        "https://docs-yoni-tech.readme.io/reference/check_getting-started"
      );
    },
    goToWhatsApp() {
      this.titleIndex = 0;
      window.open(
        "https://docs-yoni-tech.readme.io/reference/whatsapp_message-send"
      );
    },
    mouseleave() {
      this.titleIndex = 0;
    },
    goToSMSApi() {
      window.open(
        "https://docs-yoni-tech.readme.io/reference/sms_getting-started"
      );
    },
    goToSMSWhats() {
      window.open(
        "https://docs-yoni-tech.readme.io/reference/whatsapp_getting-started"
      );
    },
    goToPrice() {
      this.$router.push("/price");
    },
    onDocment() {
      window.open("https://docs-yoni-tech.readme.io/reference/summary");
    },
    imgLoad() {
      this.showBannerText = true;
      this.$emit("load", "");
    },
    chooseLanguage(e) {
      this.language = e;
      document.documentElement.scrollTop = 0;
      document.title =
        e == "CN"
          ? "优尼科技-全球短信/语音，更快速，更专业, 直连运营商，覆盖全球国家"
          : "YoniTech-Global SMS/Voice, faster and more professional, directly connected to operators, covering countries around the world";
      localStorage.setItem("language", e);
      this.$emit("change", "");
    },
    toLogin() {
      let lang = this.language == 'EN' ? 'en' : 'zh'
      window.open(`https://sgzz.yoni-tech.com/login?lang=${lang}`);
    },
    toSignUp() {
      let lang = this.language == 'EN' ? 'en' : 'zh'
      window.open(`https://sgzz.yoni-tech.com/login?lang=${lang}&register=1`);
    },
  },
};
</script>

<style lang="scss" scoped>
.product_WA {
  width: 150px !important;
  text-align: left !important;
  padding-left: 15px !important;
  img {
    padding-right: 5px;
  }
}
.stk {
  width: 100%;
  height: 100vh;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }

  .stkBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #424267 !important;
    opacity: 0.5 !important;
    z-index: 2;
  }
}
.language {
  position: relative;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 12px;
    margin-left: 2px;
    line-height: 1;
    transition: all 0.4s ease;
  }
  &:hover {
    i {
      transform: rotate(180deg);
    }
    .language-select {
      display: block;
    }
  }

  .languageBg {
    position: absolute;
    left: -30px !important;
    width: 300px;
    height: 300px;
  }

  .top1 {
    top: 70px !important;
  }

  .top2 {
    top: 154px !important;
  }

  .select1 {
    &:hover {
      .language-select {
        display: block !important;
      }
    }
  }

  .language-select1 {
    width: 85% !important;
  }

  .language-select {
    display: none;
    padding-top: 5%;
    position: absolute;
    top: 70%;
    left: 0;
    width: 100%;
    padding: 20px;
    &:hover {
      display: block;
    }

    .triangle-up {
      z-index: 202;
      position: absolute;
      top: calc(110% - 8px);
      left: 50%;
      margin-left: -5px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid #ffffff;
    }

    .triangle-up1 {
      left: -7% !important;
      top: 152% !important;
      border-left: 8px solid transparent !important;
      border-right: 8px solid #ffffff !important;
      border-bottom: 10px solid transparent !important;
      border-top: 10px solid transparent !important;
    }

    .jiantou {
      display: flex;
      justify-content: space-between;
      padding-left: 25px !important;
      position: relative;
      img {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -56%);
        width: 25px;
        height: 25px;
        vertical-align: middle;
      }
    }

    .languageWidth {
      width: 180px !important;
    }

    .triangle-up2 {
      left: -7% !important;
      top: 170% !important;
      border-left: 8px solid transparent !important;
      border-right: 8px solid #ffffff !important;
      border-bottom: 10px solid transparent !important;
      border-top: 10px solid transparent !important;
    }

    .triangle-up4 {
      left: -7% !important;
      top: 231% !important;
      border-left: 8px solid transparent !important;
      border-right: 8px solid #ffffff !important;
      border-bottom: 10px solid transparent !important;
      border-top: 10px solid transparent !important;
    }

    .languageLeft {
      left: 190px !important;
    }

    .triangle-up3 {
      left: -6% !important;
      top: 617% !important;
      border-left: 8px solid transparent !important;
      border-right: 8px solid #ffffff !important;
      border-bottom: 10px solid transparent !important;
      border-top: 10px solid transparent !important;
    }
    .triangle-up-shadow {
      z-index: 199;
      position: absolute;
      top: calc(110% - 11px);
      left: 50%;
      margin-left: -7px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 12px solid rgba(0, 0, 0, 0.02);
    }
    .language-select-item {
      z-index: 200;
      position: absolute;
      top: 110%;
      left: 0;
      width: 100%;
      background-color: #fff;
      text-align: center;
      border-radius: 8px;
      padding: 10px 0;
      box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
      &.product-item {
        padding: 0;

        > div {
          cursor: text;
          flex-grow: 1;
          border-left: 1px solid #e2e2e2;
          padding: 8px 0;
          cursor: pointer;

          &:first-of-type {
            border-left: 0;
          }
          .product-title {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            margin-bottom: 5px;
            text-align: left !important;
          }
          .product-row {
            text-align: left !important;
            box-sizing: border-box;
            padding-left: 15px;
            img {
              width: 20px;
              height: 20px;
              vertical-align: middle;
              padding-right: 6px !important;
            }
            cursor: pointer;
            height: 50px;
            line-height: 50px;
            margin: 0 12px;

            a {
              color: black !important;
            }
            &.active {
              background-color: #e2e2e2;
              a {
                color: rgba(4, 18, 61, 0.7);
              }
            }
            &:hover {
              background-color: #e2eff7;
            }
          }
        }
      }
      &.language-select {
        > div {
          height: 50px;
          line-height: 50px;
          &:hover {
            background-color: #e2eff7;
          }
        }
        .active {
          background-color: #e2e2e2;
        }
      }
    }
  }
}

.width {
  width: 120% !important;
}

.font_detail {
  width: 65%;
  font-size: 30px !important;
  color: #fff !important;
  line-height: 1.5em !important;
  font-weight: 800 !important;
  position: absolute;
  bottom: 130px;
  z-index: 33;
  left: 40px;
}

.jiage {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 16px;
  width: 150px;
  border: 2px solid #fff;
  cursor: pointer;

  &:hover {
    background: #108ccf;
  }
}

.jianjie {
  line-height: 1.5em !important;
  font-size: 18px;
  width: 60%;
  color: #fff !important;
}

.scroll {
  background-color: #fff !important;
  .menu-item {
    color: black !important;
  }

  .login-btn {
    border: 1px solid #393939 !important;
  }

  a:hover {
    color: #6236ff !important;
  }

  a {
    color: black !important;
  }

  .menu-item {
    &.active {
      a {
        color: #6236ff !important;
      }
    }
  }

  .sign-up {
    border: 0px !important;
  }
}
.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  .banner-menu {
    background-color: #fff;
    padding: 0 5%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      height: 40px;
    }
    .menu {
      display: flex;
      height: calc(100% - 5px);
      align-items: center;
      .menu-item {
        font-size: 12px;
        height: 100%;
        line-height: 60px;
        padding: 0 18px;
        cursor: pointer;
        border-bottom: 5px solid transparent;
        color: black;
        font-weight: 600;
        a {
          color: black;
        }
        // &.active {
        //   a {
        //     color: #fff;
        //   }
        // }
        a:hover {
          color: #6236ff;
        }
      }
      .login-btn {
        border: 1px solid #393939;
        border-radius: 30px;
        padding: 0 20px;
        margin: 0 16px;
        height: 38px;
        line-height: 38px;
        &:hover {
          color: #6236ff;
          border-color: #6236ff;
        }
        &.sign-up {
          border: 0;
          padding-left: 0;
        }
      }
      .language {
        border-bottom: 0;
        &.product {
          border-bottom: 5px solid transparent;
          border-top: 5px solid transparent;
        }
        &.active {
          a {
            color: rgba(4, 18, 61, 0.7);
          }
          .active {
            a {
              color: #108ccf;
            }
          }
          a:hover {
            color: #118ccf;
          }
        }
      }
    }
  }
}

.smsBanner {
  text-align: left !important;
  padding-left: 25px !important;
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    padding-right: 6px;
  }
}

.smsBanner2 {
  text-align: left !important;
  padding-left: 25px !important;
  img {
    width: 22px;
    height: 22px;
    vertical-align: middle;
    padding-right: 4px;
  }
}

.smsBanner1 {
  text-align: left !important;
  padding-left: 25px !important;
  img {
    width: 22px;
    height: 22px;
    vertical-align: middle;
    padding-right: 6px;
  }
}
.banner-img {
  padding-top: 100px;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    display: block;
  }
  .banner-text {
    position: absolute;
    top: 35%;
    right: 0;
    width: 70%;
    text-align: center;
    font-size: 32px;
    .big {
      font-size: 48px;
      margin-bottom: 2%;
    }
  }
  .swiper-pagination {
    bottom: 10px;
    left: 0;
  }
}


</style>
