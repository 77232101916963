import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/job',
    name: 'Job',
    component: () => import('../views/Job.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/sms',
    name: 'SMS',
    component: () => import('../views/SMS.vue')
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('../views/Market.vue')
  },
  {
    path: '/voice',
    name: 'Voice',
    component: () => import('../views/Voice.vue')
  },
  {
    path: '/trunk',
    name: 'Trunk',
    component: () => import('../views/Trunk.vue')
  },
  {
    path: '/phoneCheck',
    name: 'Platform',
    component: () => import('../views/Platform.vue')
  },
  {
    path: '/price',
    name: 'Price',
    component: () => import('../views/Price.vue')
  },
  {
    path: '/docment',
    name: 'Docment',
    component: () => import('../views/Docment.vue')
  },
  {
    path: '/termsService',
    name: 'TermsService',
    component: () => import('../views/TermsService.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  
  {
    path: '/whatsApp',
    name: 'WhatsApp',
    component: () => import('../views/whatsApp.vue')
  },
  {
    path: '/campaign',
    name: 'Campaign',
    component: () => import('../views/Campaign.vue')
  },
  {
    path: '/bot',
    name: 'Bot',
    component: () => import('../views/Bot.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/growthTool',
    name: 'GrowthTool',
    component: () => import('../views/GrowthTool.vue')
  },
  {
    path: '/CTWA',
    name: 'CTWA',
    component: () => import('../views/CTWA.vue')
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: () => import('../views/Inbox.vue')
  },
  
  
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
