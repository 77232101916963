<template>
  <div>
    <div class="stk animate__animated animate__rotateInDownLeft">
      <img src="./../assets/logoWeb.png" style="width: 50px; height: 50px;opacity: 0;"/>
      <!-- <div class="stkBg"></div> -->
      <img src="./../assets/45.png" class="stkBg1" alt="" />
      <img src="./../assets/Seo.png" class="stkBg2" alt="" />
      <div class="font_detail" :class="{ font_detail1: language == 'EN' }">
        <h2>{{ word.menu.titleTop[language] }}</h2>
        <p class="jianjie">
          {{ word.menu.jieshao[language] }}
        </p>

        <div class="jiage" @click="goToPrice">
          {{ word.menu.jiage[language] }}
        </div>
      </div>
      <div class="container">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
    </div>
    <div style="padding-bottom: 80px">
      <div id="title"  class="title">
        {{ word.menu.productTitle[language] }}
      </div>

      <div class="smallTitle">
        {{ word.menu.smallTitle[language] }}
      </div>

      <div class="homeMiddle show" id="project">
        <div class="homeMiddle_left" :class="{width: language == 'EN'}">
          <img src="./../assets/otp.jpg"  alt="" />
        </div>
        <div class="homeMiddle_right">
          <h2 class="homeMiddle_right_title">
            {{ word.menu.projectList1[language] }}
          </h2>
          <p class="title-p">
            {{ word.menu.projectListDesc1[language] }}
          </p>

          <div class="homeMiddle_list">
            <h3 class="top">{{ word.product.otp[language] }}</h3>
            <div class="bottom">{{ word.menu.otpDesc[language] }}</div>
          </div>

          <div class="homeMiddle_list">
            <h3 class="top">{{ word.product.marketing[language] }}</h3>
            <div class="bottom">{{ word.menu.marketDesc[language] }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="reliable show" id="project1">
      <div class="reliable-item">
        <div class="reliable-item-img">
          <img src="./../assets/reliable-2.png" alt="" />
        </div>
        <div
          class="reliable-text animate__animated"
          :class="scroll.reliable > 1 ? 'animate__backInUp' : ''"
        >
          <div class="reliable-title">
            {{ word.reliable.rate1[language] }}
          </div>
          <div>99%</div>
          <div class="reliable-sub">
            {{ word.reliable.rate2[language] }}
          </div>
        </div>
      </div>
    </div>
    <div class="homeMiddle show" id="project2">
      <div class="homeMiddle_left" :class="{width1: language == 'EN'}">
        <img src="./../assets/voice.jpg" alt="" />
      </div>
      <div class="homeMiddle_right">
        <h2 class="homeMiddle_right_title">
          {{ word.menu.projectList2[language] }}
        </h2>
        <p class="title-p">{{ word.menu.projectListDesc2[language] }}</p>

        <div class="homeMiddle_list">
          <h3 class="top">{{ word.product.voice[language] }}</h3>
          <div class="bottom">{{ word.menu.voiceDesc[language] }}</div>
        </div>

        <div class="homeMiddle_list">
          <h3 class="top">SIP Trunk</h3>
          <div class="bottom">{{ word.menu.trunkDesc[language] }}</div>
        </div>
      </div>
    </div>
    <div class="reliable show" id="project3" style="margin-top: 150px">
      <div class="reliable-item">
        <div class="reliable-item-img">
          <img src="./../assets/reliable-4.png" alt="" />
        </div>
        <div
          class="reliable-text animate__animated"
          :class="scroll.reliable > 3 ? 'animate__backInUp' : ''"
        >
          <div class="reliable-title">
            {{ word.reliable.time1[language] }}
          </div>
          <div>{{ word.reliable.time2[language] }}</div>
          <div class="reliable-sub">
            {{ word.reliable.time3[language] }}
          </div>
        </div>
      </div>
    </div>
    <div class="homeMiddle show" id="project4">
      <div class="homeMiddle_left homeMiddleHeight" :class="{width3: language == 'EN'}">
        <img src="./../assets/check.jpg" alt="" />
      </div>
      <div class="homeMiddle_right">
        <h2 class="homeMiddle_right_title">
          {{ word.menu.projectList3[language] }}
        </h2>
        <div class="homeMiddle_list">
          <h3 class="top">{{ word.product.number[language] }}</h3>
          <div class="bottom">{{ word.menu.phoneDesc[language] }}</div>
        </div>
        <div class="homeMiddle_right_list">
          <img
            src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGVjay1jaXJjbGUiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGVjay1jaXJjbGUgZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZmlsbDogIzc0NTZmMSAhaW1wb3J0YW50OyBjb2xvcjogIzc0NTZmMSAhaW1wb3J0YW50O3RyYW5zZm9ybTogcm90YXRlKGRlZykgIWltcG9ydGFudDsiPjxwYXRoIGZpbGw9IiM3NDU2ZjEiIGQ9Ik01MDQgMjU2YzAgMTM2Ljk2Ny0xMTEuMDMzIDI0OC0yNDggMjQ4UzggMzkyLjk2NyA4IDI1NiAxMTkuMDMzIDggMjU2IDhzMjQ4IDExMS4wMzMgMjQ4IDI0OHpNMjI3LjMxNCAzODcuMzE0bDE4NC0xODRjNi4yNDgtNi4yNDggNi4yNDgtMTYuMzc5IDAtMjIuNjI3bC0yMi42MjctMjIuNjI3Yy02LjI0OC02LjI0OS0xNi4zNzktNi4yNDktMjIuNjI4IDBMMjE2IDMwOC4xMThsLTcwLjA1OS03MC4wNTljLTYuMjQ4LTYuMjQ4LTE2LjM3OS02LjI0OC0yMi42MjggMGwtMjIuNjI3IDIyLjYyN2MtNi4yNDggNi4yNDgtNi4yNDggMTYuMzc5IDAgMjIuNjI3bDEwNCAxMDRjNi4yNDkgNi4yNDkgMTYuMzc5IDYuMjQ5IDIyLjYyOC4wMDF6IiBzdHJva2U9IiM3NDU2ZjEiIHN0eWxlPSJmaWxsOiByZ2IoMTE2LCA4NiwgMjQxKTsgc3Ryb2tlOiByZ2IoMTE2LCA4NiwgMjQxKTsiLz48L3N2Zz4="
            alt=""
          />
          {{ word.menu.phoneDesc1[language] }}
        </div>
        <div class="homeMiddle_right_list">
          <img
            src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGVjay1jaXJjbGUiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGVjay1jaXJjbGUgZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZmlsbDogIzc0NTZmMSAhaW1wb3J0YW50OyBjb2xvcjogIzc0NTZmMSAhaW1wb3J0YW50O3RyYW5zZm9ybTogcm90YXRlKGRlZykgIWltcG9ydGFudDsiPjxwYXRoIGZpbGw9IiM3NDU2ZjEiIGQ9Ik01MDQgMjU2YzAgMTM2Ljk2Ny0xMTEuMDMzIDI0OC0yNDggMjQ4UzggMzkyLjk2NyA4IDI1NiAxMTkuMDMzIDggMjU2IDhzMjQ4IDExMS4wMzMgMjQ4IDI0OHpNMjI3LjMxNCAzODcuMzE0bDE4NC0xODRjNi4yNDgtNi4yNDggNi4yNDgtMTYuMzc5IDAtMjIuNjI3bC0yMi42MjctMjIuNjI3Yy02LjI0OC02LjI0OS0xNi4zNzktNi4yNDktMjIuNjI4IDBMMjE2IDMwOC4xMThsLTcwLjA1OS03MC4wNTljLTYuMjQ4LTYuMjQ4LTE2LjM3OS02LjI0OC0yMi42MjggMGwtMjIuNjI3IDIyLjYyN2MtNi4yNDggNi4yNDgtNi4yNDggMTYuMzc5IDAgMjIuNjI3bDEwNCAxMDRjNi4yNDkgNi4yNDkgMTYuMzc5IDYuMjQ5IDIyLjYyOC4wMDF6IiBzdHJva2U9IiM3NDU2ZjEiIHN0eWxlPSJmaWxsOiByZ2IoMTE2LCA4NiwgMjQxKTsgc3Ryb2tlOiByZ2IoMTE2LCA4NiwgMjQxKTsiLz48L3N2Zz4="
            alt=""
          />
          {{ word.menu.phoneDesc2[language] }}
        </div>
      </div>
    </div>
    <div class="reliable show" id="project5" style="margin-top: 150px">
      <div class="reliable-item">
        <div
          class="reliable-text animate__animated"
          :class="scroll.reliable > 2 ? 'animate__backInUp' : ''"
        >
          <div class="reliable-title">
            {{ word.reliable.countries1[language] }}
          </div>
          <div>{{ word.reliable.countries2[language] }}</div>
          <div class="reliable-sub">
            {{ word.reliable.countries3[language] }}
          </div>
        </div>
        <div class="reliable-item-img">
          <img src="./../assets/reliable-3.png" alt="" />
        </div>
      </div>
    </div>

    <div class="homeMiddle show" id="project6">
      <div id="homeMiddleleft" class="homeMiddle_left height" :class="{width4: language == 'EN'}">
        <img src="./../assets/whatsAppPhone.jpg" alt="" />
      </div>
      <div id="homeMiddleright" class="homeMiddle_right">
        <h2 class="homeMiddle_right_title">
          {{ word.menu.projectList4[language] }}
        </h2>
        <p class="title-p">
          {{ word.menu.projectListDesc7[language] }}
        </p>
        <div class="homeMiddle_list">
          <div class="homeMiddle_list_img">
            <!-- <img src="./../assets/whatsAppKeFu.png" alt="" /> -->
            <h3 class="top">{{ word.product.marketing11[language] }}</h3>
          </div>

          <div class="bottom">{{ word.menu.marketDesc15[language] }}</div>
        </div>
        <div class="homeMiddle_list">
          <div class="homeMiddle_list_img">
            <!-- <img src="./../assets/shouhou.png" alt="" /> -->
            <h3 class="top">{{ word.product.marketing12[language] }}</h3>
          </div>

          <div class="bottom">{{ word.menu.marketDesc16[language] }}</div>
        </div>
      </div>
    </div>

    <div id="homeFooter" v-if="word" style="margin-top: 50px">
      <div v-if="!mode" id="special" class="special">
        <div
          id="special-point"
          class="special-point"
          :class="language == 'EN' ? 'english' : ''"
        >
          <div id="block" class="special-item">
            <div class="special-icon">
              <img src="./../assets/fmt.png" alt="" />
            </div>

            <div class="special-item-title">
              <span class="fontStyle"><span id="font1"></span>+</span>
              {{ word.special.special63[language] }}
            </div>
            <div>
              {{ word.special.special64[language] }}
            </div>
          </div>
          <div id="block" class="special-item">
            <div class="special-icon">
              <img src="./../assets/jiangdi.png" alt="" />
            </div>
            <div class="special-item-title">
              <span class="fontStyle"><span id="font2"></span>%</span>
              {{ word.special.special65[language] }}
            </div>
            <div>
              {{ word.special.special66[language] }}
            </div>
          </div>
          <div id="block" class="special-item animate__animated">
            <div class="special-icon">
              <img src="./../assets/yuedu.png" alt="" />
            </div>
            <div class="special-item-title">
              <span class="fontStyle"><span id="font3"></span>%</span>
              {{ word.special.special67[language] }}
            </div>
            <div>
              {{ word.special.special68[language] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="homeFooter1" class="show" v-if="word">
      <!-- <div v-if="!mode" id="special" class="special">
        <div class="title">{{ word.special.title[language] }}</div>
        <div class="title_desc">
          {{ word.reliable.monthly1[language]
          }}{{ word.reliable.monthly2[language] }}
        </div>
        <div
          id="special-point"
          class="special-point"
          :class="language == 'EN' ? 'english' : ''"
        >
          <div
            id="block"
            class="show special-item"
            :class="scroll.special ? 'animate__flipInY' : ''"
          >
            <div class="special-icon"><i class="iconfont">&#xe68d;</i></div>
            <div class="special-item-title">
              {{ word.special.special11[language] }}
            </div>
            <div>{{ word.special.special12[language] }}</div>
          </div>
          <div
            id="block"
            class="show special-item"
            :class="scroll.special ? 'animate__flipInY' : ''"
          >
            <div class="special-icon"><i class="iconfont">&#xeb66;</i></div>
            <div class="special-item-title">
              {{ word.special.special21[language] }}
            </div>
            <div>{{ word.special.special22[language] }}</div>
          </div>
          <div
            id="block"
            class="show special-item animate__animated"
            :class="scroll.special ? 'animate__flipInY' : ''"
          >
            <div class="special-icon"><i class="iconfont">&#xe6ad;</i></div>
            <div class="special-item-title">
              {{ word.special.special31[language] }}
            </div>
            <div>{{ word.special.special32[language] }}</div>
          </div>
          <div
            id="block"
            class="show special-item animate__animated"
            :class="scroll.special ? 'animate__flipInY' : ''"
          >
            <div class="special-icon"><i class="iconfont">&#xe636;</i></div>
            <div class="special-item-title">
              {{ word.special.special41[language] }}
            </div>
            <div>{{ word.special.special42[language] }}</div>
          </div>
          <div
            id="block"
            class="show special-item animate__animated"
            :class="scroll.special ? 'animate__flipInY' : ''"
          >
            <div class="special-icon"><i class="iconfont">&#xe603;</i></div>
            <div class="special-item-title">
              {{ word.special.special51[language] }}
            </div>
            <div>{{ word.special.special52[language] }}</div>
          </div>
          <div
            id="block"
            class="show special-item animate__animated"
            :class="scroll.special ? 'animate__flipInY' : ''"
          >
            <div class="special-icon"><i class="iconfont">&#xe694;</i></div>
            <div class="special-item-title">
              {{ word.special.special61[language] }}
            </div>
            <div>{{ word.special.special62[language] }}</div>
          </div>
        </div>
      </div> -->
      <div style="height: 300px"></div>
      <div :class="{ alone: mode }" class="form">
        <div class="form-left">{{ word.foot.form.tips[language] }}</div>
        <div class="form-body">
          <div class="form-input">
            <textarea
              :placeholder="word.foot.form.tips[language]"
              rows="4"
              cols="20"
              style="min-height: 33px"
              v-model="form.content"
            ></textarea>
          </div>
          <div class="form-input">
            <input
              type="text"
              :placeholder="word.foot.form.name[language]"
              autocomplete="off"
              class="el-input__inner"
              v-model="form.name"
            />
          </div>
          <div class="form-input">
            <input
              type="text"
              :placeholder="word.foot.form.phone[language]"
              autocomplete="off"
              class="el-input__inner"
              v-model="form.phone"
            />
          </div>
          <div class="form-input">
            <input
              type="tel"
              :placeholder="word.foot.form.email[language]"
              autocomplete="off"
              class="el-input__inner"
              v-model="form.email"
            />
          </div>
          <button
            class="form-button"
            :class="{ disable: sec > 0 }"
            @click="toSubmit"
          >
            {{
              sec > 0
                ? language == "EN"
                  ? word.foot.form.countdown[language] +
                    " " +
                    sec +
                    " " +
                    word.foot.form.sec[language]
                  : sec +
                    word.foot.form.sec[language] +
                    word.foot.form.countdown[language]
                : word.foot.form.buttonText[language]
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";
import "animate.css";
import axios from "@/utils/request/request.js";
import base from "@/utils/request/base.js";
import word from "./../assets/json/language.json";

export default {
  name: "Home",
  components: {},
  props: {
    mode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isScrool: false,
      domShow1: false,
      domShow: false,
      domShow2: false,
      product: 1,
      sec: 0,
      language: "",
      word: word,
      scrollTop: 0,
      defaultHeight: 900,
      scroll: {
        reliable: 0,
        special: false,
      },
      friendList: [
        "tengxunyun",
        "aliyun",
        "boc",
        "huohua",
        "facebook",
        "google",
      ],
      form: {
        name: "",
        phone: "",
        email: "",
        content: "",
      },
    };
  },
  created() {
    this.sec = localStorage.getItem("sec");
    if (this.sec > 0) {
      let countdown = setInterval(() => {
        localStorage.setItem("sec", this.sec);
        this.sec--;
        if (this.sec == 0) {
          localStorage.removeItem("sec");
          clearInterval(countdown);
        }
      }, 1000);
    }
    this.language = localStorage.getItem("language");
    this.defaultHeight =
      (700 * window.innerWidth) / 1920 - window.innerHeight / 2;
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 可选的平滑滚动效果
    });
    const element1 = document.getElementById("title");
    const element4 = document.getElementById("project");
    const element7 = document.getElementById("project1");
    const element8 = document.getElementById("project2");
    const element9 = document.getElementById("project3");
    const element11 = document.getElementById("project5");
    const element10 = document.getElementById("project4");
    const element12 = document.getElementById("project6");
    const element13 = document.getElementById("homeFooter");

    const element2 = document.getElementById("homeFooter1");

    if (this.mode) {
      document.querySelector("meta[name=viewport]").content =
        "width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no";
    }
    window.addEventListener("scroll", (res) => {
      const scrollTop = document.documentElement.scrollTop; // 查看滑动的距离
      const top1 = element1?.offsetTop;

      if (scrollTop > top1 / 1.3) {
        element4?.classList.remove("show");
        element4?.classList.add("animate__animated");
        element4?.classList.add("animate__fadeInUp");
        const top2 = element7?.offsetTop;
        if (scrollTop > top2 - 200) {
          element7?.classList.remove("show");
          element7?.classList.add("animate__animated");
          element7?.classList.add("animate__fadeInUp");
          const top3 = element8?.offsetTop;
          if (scrollTop > top3 - 200) {
            element8?.classList.remove("show");
            element8?.classList.add("animate__animated");
            element8?.classList.add("animate__fadeInUp");
            const top4 = element9?.offsetTop;
            if (scrollTop > top4 - 200) {
              element9?.classList.remove("show");
              element9?.classList.add("animate__animated");
              element9?.classList.add("animate__fadeInUp");
              const top5 = element10?.offsetTop;
              if (scrollTop > top5 - 250) {
                element10?.classList.remove("show");
                element10?.classList.add("animate__animated");
                element10?.classList.add("animate__fadeInUp");
                const top7 = element11?.offsetTop;
                if (scrollTop > top7 - 250) {
                  element11?.classList.remove("show");
                  element11?.classList.add("animate__animated");
                  element11?.classList.add("animate__fadeInUp");
                  const top8 = element12?.offsetTop;
                  if (scrollTop > top8 - 250) {
                    element12?.classList.remove("show");
                    element12?.classList.add("animate__animated");
                    element12?.classList.add("animate__fadeInUp");
                    const top9 = element13?.offsetTop;
                    if (scrollTop > top9 - 300) {
                      if (!this.isScrool) {
                        const element77 = document.getElementById("font1");
                        let dom = new CountUp(element77, 8);
                        dom.start();
                        const element88 = document.getElementById("font2");
                        let dom1 = new CountUp(element88, 70);
                        dom1.start();
                        const element99 = document.getElementById("font3");
                        let dom2 = new CountUp(element99, 75);
                        dom2.start();
                        this.isScrool = true;
                      }
                      const top10 = element2?.offsetTop;
                      if (scrollTop > top10 - 500) {
                        element2?.classList.remove("show");
                        element2?.classList.add("animate__animated");
                        element2?.classList.add("animate__bounceInUp");
                        const block = document.querySelectorAll("#block");
                        block.forEach((item, index) => {
                          setTimeout(() => {
                            item?.classList.remove("show");
                            item?.classList.add("keyframes");
                          }, index * 500);
                        });
                      }
                    }
                  }
                }

                //     if (scrollTop > top6 - 220) {
                //       const block = document.querySelectorAll("#block");
                //       block.forEach((item, index) => {
                //         setTimeout(() => {
                //           item.classList.remove("show");
                //           item.classList.add("keyframes");
                //         }, index * 600);
                //       });
                //     }
                //   }
                // }
              }
            }
          }
        }
      }


      this.scrollTop =
        document.documentElement.scrollTop > this.scrollTop
          ? document.documentElement.scrollTop
          : this.scrollTop;
      this.scroll.reliable =
        Math.floor((this.scrollTop - this.defaultHeight) / 270) + 1;
      this.scroll.special =
        this.scroll.reliable > 3 && this.scrollTop > 1680 + this.defaultHeight;
    });
  },
  methods: {
    goToPrice() {
      this.$router.push("/price");
    },
    onOneProject() {
      this.product = 1;
      setTimeout(() => {
        const element4 = document.getElementById("middle_project");
        element4?.classList.remove("show");
        element4?.classList.add("animate__animated");
        element4?.classList.add("animate__fadeInUp");
      }, 200);
    },
    toSubmit() {
      if (this.sec > 0) {
        return false;
      }
      if (!this.form.content) {
        let message =
          this.language == "CN" ? "请填写内容" : "Please fill in the content";
        this.$emit("msg", message, false);
      } else if (!this.form.name) {
        let message =
          this.language == "CN" ? "请填写您的姓名" : "Please fill in your name";
        this.$emit("msg", message, false);
      } else if (!this.form.phone && !this.form.email) {
        let message =
          this.language == "CN"
            ? "请填写您的联系方式"
            : "Please fill in your contact information";
        this.$emit("msg", message, false);
      } else {
        this.sec = 10;
        let countdown = setInterval(() => {
          localStorage.setItem("sec", this.sec);
          this.sec--;
          if (this.sec == 0) {
            localStorage.removeItem("sec");
            clearInterval(countdown);
          }
        }, 1000);
        axios
          .post(`leave-message/add`, {
            name: this.form.name,
            phone: this.form.phone,
            email: this.form.email,
            content: this.form.content,
          })
          .then((res) => {
            if (res.data == "提交成功") {
              this.form.name = "";
              this.form.phone = "";
              this.form.email = "";
              this.form.content = "";
              this.$emit(
                "msg",
                this.word.foot.form.success[this.language],
                true
              );
            } else {
              this.$emit(
                "msg",
                this.word.foot.form.error[this.language],
                false
              );
            }
          })
          .catch((res) => {
            let msg = this.language == "EN" ? "Submit Error" : "提交失败";
            this.$emit("msg", msg, false);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fontStyle {
  background: -webkit-linear-gradient(0deg, #6743e5, #b540f4);
  background-clip: border-box;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
  font-size: 30px;
  margin: 0;
  padding: 0;
  display: inline-block;
  white-space: nowrap;
}
.stk {
  width: 100%;
  height: 100vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .stkBg1 {
    transform: rotateY(180deg);
  }

  .stkBg2{
    position: absolute;
    right: 20px;
    bottom: 10% !important;
    width: 400px;
    height: 400px;
  }

  .stkBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #424267 !important;
    opacity: 0.5 !important;
    z-index: 2;
  }

  .font_detail1 {
    bottom: 150px !important;
  }

  .font_detail {
    width: 65%;
    font-size: 30px !important;
    color: #fff !important;
    line-height: 1.5em !important;
    font-weight: 800 !important;
    position: absolute;
    bottom: 35% !important;
    z-index: 33;
    left: 40px;
  }

  .jianjie {
    line-height: 1.5em !important;
    font-size: 18px;
    width: 60%;
    color: #fff !important;
  }

  .jiage {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 16px;
    width: 150px;
    border: 2px solid #fff;
    cursor: pointer;

    &:hover {
      background: #108ccf;
    }
  }
}
.show {
  opacity: 0 !important;
  transform: translateY(500px) !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.product-list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 80px;
  > div {
    font-size: 30px;
    margin: 0 60px;
    cursor: pointer;
    padding: 20px 60px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    background-color: #fff;
    transition: all 0.6s ease;
    &:hover {
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
      transform: scale(1.2);
    }
  }
  .active {
    background-color: #3e5175;
    border-color: #3e5175;
    color: #ffffff;
  }
}
.title {
  font-weight: 800 !important;
  padding: 100px 0 10px 0;
  font-size: 40px;
  text-align: center;
}

.smallTitle {
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: #4d5d6d;
}

.homeMiddleHeight {
  height: 310px !important;
  img {
    height: 100% !important;
  }
}
.reliable {
  box-sizing: border-box;
  padding: 0 8% 0 9%;

  .reliable-item {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7456f1;
    color: #f7f7f7;
    border-radius: 10px;
    &.right {
      background-color: #fff;
      color: #353535;
      .reliable-text {
        margin-left: 0;
        margin-right: 100px;
        text-align: right;
        .reliable-sub {
          color: #787878;
        }
      }
    }
    .reliable-text {
      width: 400px;
      text-align: left;
      margin-left: 100px;
      margin-bottom: 50px;
      font-size: 26px;
      .reliable-title {
        font-size: 34px;
        margin-bottom: 10px;
      }
      .reliable-sub {
        margin-top: 20px;
        font-size: 18px;
        color: #e2e2e2;
      }
    }
    .reliable-item-img {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      img {
        width: 100%;
      }
    }
  }
}

.width{
  img{
    height: 580px !important;
  }
}

.width1{
  img{
    height: 530px !important;
  }
}
.width2{
  img{
    height: 540px !important;
  }
}

.width3{
  img{
    height: 330px !important;
  }
}

.width4{
  img{
    height: 537px !important;
  }
}
.special {
  .title_desc {
    color: #353535;
    text-align: center;
    margin-bottom: 30px;
  }
  .special-point {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &.english {
      .special-item {
        font-size: 13px;
        .special-item-title {
          font-size: 16px;
          margin: 14px 0;
        }
      }
    }
    .special-item {
      background-color: #fff;
      border-radius: 16px;
      padding: 16px;
      height: 250px;
      width: 25%;
      margin: 0 1% 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 14px;
      color: #2e2e2e;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      &:hover {
        transform: scale(1.05) !important;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
        color: #108ccf;
      }
      .special-icon {
        padding-top: 20px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        i {
          font-size: 48px;
        }
        img {
          width: 50px;
          height: 50px;
        }
      }
      .special-item-title {
        margin: 20px 0;
        font-size: 26px;
      }
    }
  }
}
.friend {
  width: 70%;
  margin: 0 auto;
  padding-bottom: 50px;
  .friend-list {
    margin-top: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .friend-item {
      margin: 12px 24px;
      width: 164px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      border-radius: 16px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      img {
        width: 144px;
      }
    }
  }
}
#homeFooter {
  position: relative;
}
.form {
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translate(-50%);
  background: url("./../assets/lianxi.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  border-radius: 20px;
  width: 90%;
  margin: 0 auto;
  background-size: 100% 100%;
  &.alone {
    overflow: hidden;
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    .form-left {
      width: 100%;
    }
    .form-body {
      width: 80%;
      margin: 50px auto 0;
      .form-button {
        width: 100%;
      }
    }
  }
  .form-left {
    width: 400px;
    font-size: 40px;
    text-align: center;
    color: #fff;
    font-weight: 700;
  }
  .form-body {
    width: 400px;
    input {
      width: 200px;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      outline: none;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }
    textarea {
      display: block;
      resize: vertical;
      padding: 5px 15px;
      line-height: 1.5;
      box-sizing: border-box;
      width: 100%;
      font-size: 16px;
      color: #606266;
      background-color: #fff;
      background-image: none;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .form-input {
      margin-bottom: 10px;
    }
    .form-button {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: #108ccf;
      border: 1px solid #108ccf;
      color: #fff;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: none;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      padding: 12px 0;
      width: 400px;
      font-size: 14px;
      border-radius: 4px;
      &.disable {
        color: #fff;
        background-color: #a0cfff;
        border-color: #a0cfff;
        cursor: not-allowed;
      }
    }
  }
}

.homeMiddle {
  padding-top: 30px;
  display: flex;
  &_left {
    padding: 100px 50px 0 50px;
    width: 50%;
    img {
      border-radius: 10px;
      width: 100%;
      height: 478px;
    }
  }
  &_right {
    flex: 1;
    box-sizing: border-box;
    padding-right: 30px;

    .homeMiddle_right_title {
      font-size: 45px;
      line-height: 10px;
      font-weight: 800;
      padding-top: 80px;
    }

    .title-p {
      color: #4d5d6d;
    }
    .homeMiddle_right_list {
      color: #4d5d6d;
      display: inline-block;
      margin-top: 30px;
      width: 50%;
      img {
        width: 19px;
        height: 19px;
        vertical-align: middle;
      }
    }

    .homeMiddle_list {
      background-color: #f3f5f7 !important;
      border-radius: 10px !important;
      overflow: hidden !important;
      padding-top: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 30px !important;
      padding-left: 30px !important;

      cursor: pointer;

      .top {
        font-size: 18px;
        font-weight: 700;
      }

      &:hover {
        background: #fff !important;
        box-shadow: 0px 0px 24px -10px #000 !important;
      }
    }

    .bottom {
      color: #4d5d6d;
    }
  }
}
@keyframes topIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.keyframes {
  animation: topIn 2s ease forwards !important;
}

.message-content {
  position: absolute;
  z-index: 2;
  background-color: #ececec;
  font-size: 14px;
  padding: 12px 20px;
  width: 230px;
  letter-spacing: 0.5px;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  & + img {
    border-radius: 10px;
  }
}
</style>
